import http from "./httpService";
import auth from "./authServices";
import config from "../config.json";

export function validatePromoCode(userDetails, promoCode) {
  return http.post(config.apiEndPoint + "/check-coupon", {
    promocode: promoCode,
    applicableFor: "subscription",
    userEmail: userDetails.email,
  });
}

export function applyPromoCode(orderId, promoCode) {
  return http.post(config.apiEndPoint + "/apply-promo", {
    orderId: orderId,
    usedPromocode: promoCode,
  });
}

export function createOrderSubscription(
  userDetails,
  selectedPlan,
  addressDetails,
  promocode,
  promoDiscountAmount,
  finalPaybleAmount,
  couponCodeApplied,
  cardDetails
) {
  let promoCodeUsed = "";

  if (couponCodeApplied) {
    promoCodeUsed = "YES";
  } else {
    promoCodeUsed = "NO";
  }

  let orderPayload = {
    orderType: "subscription",
    customer: userDetails._id,
    userEmail: userDetails.email,
    subscriptionId: selectedPlan.subscriptionId,
    shippingName:
      addressDetails.shippingFirstName + " " + addressDetails.shippingLastName,
    shippingAddress: addressDetails.shippingAddress,
    shippingCity: addressDetails.shippingCity,
    shippingPostcode: addressDetails.shippingPostcode,
    shippingState: addressDetails.shippingState,
    shippingCountry: addressDetails.shippingCountry,
    billingName:
      addressDetails.billingFirstName + " " + addressDetails.billingLastName,
    billingAddress: addressDetails.billingAddress,
    billingCity: addressDetails.billingCity,
    billingPostcode: addressDetails.billingPostcode,
    billingState: addressDetails.billingState,
    billingCountry: addressDetails.billingCountry,
    useValidPromocode: promoCodeUsed,
    usedPromocode: promocode,
    cartTotalAmount: selectedPlan.price,
    promoDiscountAmount: promoDiscountAmount,
    finalPaybleAmount: finalPaybleAmount,
    totalPurchaseItems: "1",
    orderGroup: userDetails.orderGroup
  };

  if (cardDetails) {
    orderPayload = Object.assign({}, orderPayload, cardDetails);
  }

  if (userDetails.stripeCustId) {
    orderPayload = Object.assign({}, orderPayload, {
      stripeCustId: userDetails.stripeCustId,
    });
  }
  console.log('payload: ', orderPayload);


  return http.post(config.apiEndPoint + "/create-order", orderPayload);
}

export function createOrderSubscriptionPaypal(
  userDetails,
  selectedPlan,
  addressDetails,
  promocode,
  promoDiscountAmount,
  finalPaybleAmount,
  couponCodeApplied,
  paypalSubscriptionID,
  cardDetails
){
  let promoCodeUsed = "";
  if (couponCodeApplied) {
    promoCodeUsed = "YES";
  } else {
    promoCodeUsed = "NO";
  }
  let orderPayload = {
    orderType: "subscription",
    customer: userDetails._id,
    userEmail: userDetails.email,
    subscriptionId: selectedPlan.subscriptionId,
    shippingName:
      addressDetails.shippingFirstName + " " + addressDetails.shippingLastName,
    shippingAddress: addressDetails.shippingAddress,
    shippingCity: addressDetails.shippingCity,
    shippingPostcode: addressDetails.shippingPostcode,
    shippingState: addressDetails.shippingState,
    shippingCountry: addressDetails.shippingCountry,
    billingName:
      addressDetails.billingFirstName + " " + addressDetails.billingLastName,
    billingAddress: addressDetails.billingAddress,
    billingCity: addressDetails.billingCity,
    billingPostcode: addressDetails.billingPostcode,
    billingState: addressDetails.billingState,
    billingCountry: addressDetails.billingCountry,
    useValidPromocode: promoCodeUsed,
    usedPromocode: promocode,
    cartTotalAmount: selectedPlan.price,
    promoDiscountAmount: promoDiscountAmount,
    finalPaybleAmount: finalPaybleAmount,
    totalPurchaseItems: "1",
    orderGroup: userDetails.orderGroup,
    paypalSubscriptionID:paypalSubscriptionID
  };
  if (userDetails.stripeCustId) {
    orderPayload = Object.assign({}, orderPayload, {
      stripeCustId: userDetails.stripeCustId,
    });
  }
  console.log("paypal orderpayload",orderPayload)
  http.post(config.apiEndPoint+"/create-order-paypal", orderPayload)
  .then(function (response) {
    if (response){
      const orderId = response.data._id;
      http.post(config.apiEndPoint + "/update-order", {
        orderId: orderId,
        orderStatus: "CONFIRMED",
      }).then(function (response) {
        if (orderId && couponCodeApplied) {
          http.post(config.apiEndPoint  + "/apply-promo", {
            orderId: orderId,
            usedPromocode: promocode,
          }).then(function (response){
            window.location.href = "/profile";
          })
          .catch(err => console.log(err))
        }
        else{
          window.location.href = "/profile";
        }
      })
      .catch(err => console.log(err))
      localStorage.setItem("OID", orderId);
    
    }
  }
  )
  .catch(err => console.log(err))

}

export function getCurrentUserOrder() {
  const user = auth.getCurrentUser();
  return http.get(config.apiEndPoint + "/get-orders/" + user._id);
}

export function updateSubscriptionOrder(orderId) {
  return http.post(config.apiEndPoint + "/update-order", {
    orderId: orderId,
    orderStatus: "CONFIRMED",
  });
}

export function DeclinedSubscriptionOrder(orderId){
  return http.post(config.apiEndPoint + "/update-order", {
    orderId: orderId,
    orderStatus: "DECLINED",
  });
}

export function cancelSubscriptionOrder(stripeSubcriptionId, orderId,paypalSubscriptionID) {
  if(paypalSubscriptionID){
    return http.post(
      config.apiEndPoint + "/cancel-subscription-paypal/"+paypalSubscriptionID ,
      {
        orderId: orderId,
      }
    );
  }else{
    return http.post(
      config.apiEndPoint + "/cancel-subscription/" + stripeSubcriptionId,
      {
        orderId: orderId,
      }
    );
  }
 
}

export function addSource(payload, orderDetails) {
  return http.post(config.apiEndPoint + "/add-sources", {
    payload,
    stripeCustomerAndOrderDetails: orderDetails,
  });
}

export function deleteSource(stripeCustId, stripeSourceCardId) {
  return http.post(config.apiEndPoint + "/delete-sources/" + stripeCustId, {
    stripeSourceCardId,
  });
}
export default {
  validatePromoCode,
  applyPromoCode,
  createOrderSubscription,
  createOrderSubscriptionPaypal,
  getCurrentUserOrder,
  updateSubscriptionOrder,
  cancelSubscriptionOrder,
  addSource,
  deleteSource,
  DeclinedSubscriptionOrder
};
