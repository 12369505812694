import React, { Component } from "react";
import { Link } from "react-router-dom";
import background1 from "../../images/kloxet_subscription_box_bg.webp";
import background3 from "../../images/kloxet-background-mobile-600x697.jpg";

class HomeBanner extends Component {
  state = {};
  render() {
    return (
      <div className="bannerHome">
        {/* <div className="container" style={{display: "flex"}}> */}
          <div className="leftTextArea">
            <p className="monthlyPrice">
              <span>Receive 5 Jewelry Pieces | Only $20</span>
              <span>HandPicked For You ♥︎ Join Now</span>
            </p>
            <h1 className="bestJewelry">
              The Best Jewelry Club
              <span>Subscription Box!</span>
            </h1>
            {!this.props.user && (
              <Link to="/quiz">
                <button className="getStratedButton fix-margin-bannerbtn" alt="get started"><span>Get Started</span></button>
              </Link>
            )}
          </div>
          {/* <div className="bannerImageArea"> */}
            
            <div
    className="landing-wrapper"
            style={{
              backgroundImage: `url(${background3})`,
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              backgroundsize: "cover",             
              backgroundposition: "top center",              
              alignitems: "center",
              justifycontent: "center",
            }}
  >    
        </div>
        <div
        className="landing-wrapper2"
            style={{
              backgroundImage: `url(${background1})`,
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              backgroundsize: "cover",             
              backgroundposition: "top center",              
              alignitems: "center",
              justifycontent: "center",
            }}
  >    
  </div>
          {/* </div> */}
        {/* This is .Container at the top </div> */}
      </div>
    );
  }
}

export default HomeBanner;
