import React, { Component } from "react";
import { injectStripe } from "react-stripe-elements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loading from "./common/loading";

import CCUtil from "../ccutil";
import order from "../services/order";
import auth from "../services/authServices";
import TextField from "@material-ui/core/TextField";
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeButtonInvalid: true,
      cardDetailsTouched: false,
      cardDetailsValid: false,
      cardExpTouched: false,
      cardExpValid: false,
      cvvTouched: false,
      cvvValid: false,
      submitting: false,
      complete: false,
      cardNum: "",
      ccExpiryMonth: "Jan",
      ccExpiryYear: "2021Y",
      cvcNum: "",
      cardType: "",
    };
  }

  handleCardDetailsInput = (event, type, setDirty) => {
    
    this.setState({ [type]: event.target.value, [setDirty]: true },(event) => {this.validateCardExpiry(event)});

  };

  validateCardNum = (ev) => {
    if (CCUtil.validateCreditCardNumber(this.state.cardNum)) {
      this.setState({
        cardType: CCUtil.validateCreditCardNumber(this.state.cardNum),
      });
      this.props.handleChangeCardType(
        CCUtil.validateCreditCardNumber(this.state.cardNum)
      );
      this.setState({ cardDetailsValid: true });
    } else {
      this.setState({ cardDetailsValid: false });

      this.props.handleChangeCardType("");
    }
  };

  validateCardExpiry = (ev) => {
  
    if (
      CCUtil.validateCardExpiry(
        this.state.ccExpiryMonth,
        this.state.ccExpiryYear
      )
    ) {
      this.setState({ cardExpValid: true });
    } else {
      this.setState({ cardExpValid: false });
    }
  };

  validateCVVNum = (ev) => {
    if (CCUtil.validateCardCVV(this.state.cvcNum) != null) {
      this.setState({ cvvValid: true });
    } else {
      this.setState({ cvvValid: false });
    }
  };

  payButtonValidation = () => {
    if (
      this.props.formValid === null &&
      this.state.cardDetailsValid &&
      this.state.cardExpValid &&
      this.state.cvvValid &&
      !this.state.submitting
    ) {
      return false;
    } else {
      return true;
    }
  };

  onCheckOut = async () => {
    this.setState({ submitting: true });
    this.props.enableSubmitting();
    const {
      user,
      allData: {
        selectedPlan,
        couponCode,
        promoDiscountAmount,
        finalPaybleAmount,
        couponCodeApplied,
        addressDetails,
      },
      stripe,
    } = this.props;
    const cardDetails = {
      sourceNo: this.state.cardNum,
      sourceExpMonth: this.state.ccExpiryMonth,
      sourceExpYear: this.state.ccExpiryYear,
      sourceCvc: this.state.cvcNum,
    };

    try {
      const { data: orderData } = await order.createOrderSubscription(
        user,
        selectedPlan,
        addressDetails,
        couponCode,
        promoDiscountAmount,
        finalPaybleAmount,
        couponCodeApplied,
        cardDetails
      );
      // save orderId in localStorage
      const orderId = orderData._id;
     if(orderId){
      const { data1 } = await order.updateSubscriptionOrder(orderId);

      localStorage.setItem("OID", orderId);
      if (orderId && couponCodeApplied) {
        await order.applyPromoCode(orderId, couponCode);
      }

      window.location.href = "/profile";
     }else{
       alert(orderData.errorMessage)
       try {
         const { data } = await order.getCurrentUserOrder();
         if (data.orderResponse.length > 0) {
          let  orderData = data;
           if (data.orderResponse[data.orderResponse.length - 1].orderStatus == "PENDING") {
            const {data}= await order.DeclinedSubscriptionOrder(data.orderResponse[data.orderResponse.length - 1]._id);
           }
         }
 
       } catch (orderEx) {
         console.log(orderEx);
       }
     }
      
    } catch (err) {

      alert("Please enter card info correctly!")
      try {
        const { data } = await order.getCurrentUserOrder();
        if (data.orderResponse.length > 0) {
         let  orderData = data;
          if (data.orderResponse[data.orderResponse.length - 1].orderStatus == "PENDING") {
           const {data}= await order.DeclinedSubscriptionOrder(data.orderResponse[data.orderResponse.length - 1]._id);
          //  window.location.href = "/profile";
          }
        }

      } catch (orderEx) {
        console.log(orderEx);
      }
      
      console.error("error: ", err);
    }

    this.setState({ submitting: false });
    this.props.disableSubmitting();
  };

  render() {
    const {
      cardDetailsValid,
      cardDetailsTouched,
      cvvValid,
      cvvTouched,
      cardExpValid,
      cardExpTouched,
    } = this.state;

    return (
      <div className="">
        <div className="row">
          <div className="form-group col" style={{ padding: "0px!important" }}>
            {/* <label htmlFor="cardNum">Card Number</label> */}
            {/* <input
              type="tel"
              onChange={(event) =>
                this.handleCardDetailsInput(
                  event,
                  "cardNum",
                  "cardDetailsTouched"
                )
              }
              onKeyUp={(event) => this.validateCardNum(event)}
              name="carNum"
              maxLength="16"
              className={
                "form-control" +
                (cardDetailsTouched && !cardDetailsValid ? " is-invalid" : "")
              }
              id="cardNum"
              disabled={this.state.submitting}
              placeholder="1234123412341234"
            /> */}
            <TextField
              style={{ border: "none!important" }}
              type="tel"              
              onChange={(event) =>
                this.handleCardDetailsInput(
                  event,
                  "cardNum",
                  "cardDetailsTouched"
                )
              }
              onKeyUp={(event) => this.validateCardNum(event)}
              name="carNum"
              inputProps={{ maxLength: 16 }}
              className={
                "form-control" +
                (cardDetailsTouched && !cardDetailsValid ? " is-invalid" : "")
              }
              id="cardNum"
              disabled={this.state.submitting}
              placeholder="CC Number"
              label="Card Number"
            />
          </div>
        </div>
        <div className="row">
          <div className='col-12 credit_card_exp' style={{display:"flex"}}>
          <div
            className="col-4 pr-1 pl-0 form-group"
            style={{ padding: "0px!important" }}
          >
            {/* <label htmlFor="ccExpiryMonth">Exp.Month</label> */}
            {/* <input
              type="tel"
              onChange={(event) =>
                this.handleCardDetailsInput(
                  event,
                  "ccExpiryMonth",
                  "cardExpTouched"
                )
              }
              name="ccExpiryMonth"
              id="ccExpiryMonth"
              maxLength="2"
              className={
                "form-control" +
                (cardExpTouched && !cardExpValid ? " is-invalid" : "")
              }
              disabled={this.state.submitting}
              onKeyUp={(event) => this.validateCardExpiry(event)}
              placeholder="01"
            /> */}
            {/* <TextField
              type="number"
              InputProps={{
                inputProps: {
                  max: 12,
                  min: 1,
                },
              }}
              onChange={(event) =>
                this.handleCardDetailsInput(
                  event,
                  "ccExpiryMonth",
                  "cardExpTouched"
                )
              }
              name="ccExpiryMonth"
              id="ccExpiryMonth"
              maxLength="2"
              className={
                "form-control" +
                (cardExpTouched && !cardExpValid ? " is-invalid" : "")
              }
              disabled={this.state.submitting}
              onKeyUp={(event) => this.validateCardExpiry(event)}
              onMouseUp={(event) => this.validateCardExpiry(event)}
              label="Month"
            /> */}
            
            <FormControl >
              <InputLabel style={{ marginBottom: "10px",width:"100%" }} htmlFor="ccExpiryMonth">
                Month
              </InputLabel>
              <Select
                type="select"
                // style={{
                //   width: "330px",
                //   minWidth: "120px",
                // }}
                onChange={(event) =>
                  this.handleCardDetailsInput(
                    event,
                    "ccExpiryMonth",
                    "cardExpTouched"
                  )
                }
                // value
                name="ccExpiryMonth"
                id="ccExpiryMonth"
                maxLength="2"
                value={this.state.ccExpiryMonth}
                className={
                  "form-control" +
                  (cardExpTouched && !cardExpValid ? " is-invalid" : "")
                }
                disabled={this.state.submitting}
                // onKeyUp={(event) => this.validateCardExpiry(event)}
                // onMouseUp={(event) => this.validateCardExpiry(event)}
                // label="Month"
              >
                
                    <MenuItem value='1'>
                      Jan
                    </MenuItem>
                    <MenuItem value='2'>
                      Feb
                    </MenuItem>
                    <MenuItem value='3'>
                      Mar
                    </MenuItem>
                    <MenuItem value={4}>  
                      Apr
                    </MenuItem>
                    <MenuItem value={5}>
                      May
                    </MenuItem>
                    <MenuItem value={6}>
                    June
                    </MenuItem>
                    <MenuItem value={7}>
                    July
                    </MenuItem>
                    <MenuItem value={8}>
                    Aug
                    </MenuItem>
                    <MenuItem value={9}>
                    Sept
                    </MenuItem>
                    <MenuItem value={10}>
                    Oct
                    </MenuItem>
                    <MenuItem value={11}>
                    Nov
                    </MenuItem>
                    <MenuItem value={12}>
                    Dec
                    </MenuItem>
                
              </Select>
            </FormControl>
          </div>
          <div
            className="col-4 ml-0 mr-0 pl-0 pr-0 form-group"
            style={{ padding: "1px!important" }}
          >
            {/* <label htmlFor="ccExpiryYear">Exp.Year</label> */}
            {/* <input
              type="tel"
              onChange={(event) =>
                this.handleCardDetailsInput(
                  event,
                  "ccExpiryYear",
                  "cardExpTouched"
                )
              }
              name="ccExpiryYear"
              id="ccExpiryYear"
              maxLength="4"
              className={
                "form-control" +
                (cardExpTouched && !cardExpValid ? " is-invalid" : "")
              }
              disabled={this.state.submitting}
              onKeyUp={(event) => this.validateCardExpiry(event)}
              placeholder="2023"
            /> */}
            {/* <TextField
              type="number"
              InputProps={{
                inputProps: {
                  max: 2050,
                  min: 2021,
                },
              }}
              onChange={(event) =>
                this.handleCardDetailsInput(
                  event,
                  "ccExpiryYear",
                  "cardExpTouched"
                )
              }
              name="ccExpiryYear"
              id="ccExpiryYear"
              maxLength="4"
              className={
                "form-control" +
                (cardExpTouched && !cardExpValid ? " is-invalid" : "")
              }
              disabled={this.state.submitting}
              onKeyUp={(event) => this.validateCardExpiry(event)}
              onMouseUp={(event) => this.validateCardExpiry(event)}
              placeholder="2023"
              label="Year"
            /> */}
            <FormControl >
              <InputLabel style={{ marginBottom: "10px" }} htmlFor="ccExpiryYear">
                Year
              </InputLabel>
              <Select
                type="select"
                // style={{
                //   width: "330px",
                //   minWidth: "120px",
                // }}
                onChange={(event) =>
                  this.handleCardDetailsInput(
                    event,
                    "ccExpiryYear",
                    "cardExpTouched"
                  )
                }
                value={
                  this.state.ccExpiryYear
                }
                placeholder="kkkkkkkkkkkk"
                name="ccExpiryYear"
                id="ccExpiryYear"
                maxLength="4"
                className={
                  "form-control" +
                  (cardExpTouched && !cardExpValid ? " is-invalid" : "")
                }
                disabled={this.state.submitting}
                onKeyUp={(event) => this.validateCardExpiry(event)}
                onMouseUp={(event) => this.validateCardExpiry(event)}
              >
                
                    <MenuItem value={2021}>
                    2021
                    </MenuItem>
                    <MenuItem value={2022}>
                    2022
                    </MenuItem>
                    <MenuItem value={2023}>
                    2023
                    </MenuItem>
                    <MenuItem value={2024}>
                    2024
                    </MenuItem>
                    <MenuItem value={2025}>
                    2025
                    </MenuItem>
                    <MenuItem value={2026}>
                    2026
                    </MenuItem>
                    <MenuItem value={2027}>
                    2027
                    </MenuItem>
                    <MenuItem value={2028}>
                    2028
                    </MenuItem>
                    <MenuItem value={2029}>
                    2029
                    </MenuItem>
                    <MenuItem value={2030}>
                    2030
                    </MenuItem>
                    <MenuItem value={2031}>
                    2031
                    </MenuItem>
                    <MenuItem value={2032}>
                    2032
                    </MenuItem>
                
              </Select>
            </FormControl>
            {/* /> */}
            {/* <CardExpiryElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                {...createOptions(this.props.fontSize)}
              /> */}
          </div>
          <div className=" col-4 pl-1 pr-1" style={{marginTop:'3px'}}>
            {/* <label htmlFor="cvcNum">Security Code (3 Digits on the back) </label> */}
            {/* <input
              type="tel"
              className={
                "form-control" + (cvvTouched && !cvvValid ? " is-invalid" : "")
              }
              onChange={(event) =>
                this.handleCardDetailsInput(event, "cvcNum", "cvvTouched")
              }
              name="cvcNum"
              maxLength="4"
              id="cvcNum"
              disabled={this.state.submitting}
              onKeyUp={(event) => this.validateCVVNum(event)}
              placeholder="CVV"
            /> */}
            <TextField
              style={{ border: "none!important" }}
              type="tel"
              className={cvvTouched && !cvvValid ? " is-invalid" : ""}
              onChange={(event) =>
                this.handleCardDetailsInput(event, "cvcNum", "cvvTouchedww")
              }
              name="cvcNum"
              inputProps={{ maxLength: 4 }}
              id="cvcNum"
              disabled={this.state.submitting}
              onKeyUp={(event) => this.validateCVVNum(event)}
              placeholder="CVC"
              label="CVC"
            />
          </div>
        </div>
        </div>
        {/* <div className="row">
          <div className="col-6 form-group">
            <label htmlFor="cvcNum">Security Code (3 Digits on the back) </label>
            <input
              type="tel"
              className={
                "form-control" + (cvvTouched && !cvvValid ? " is-invalid" : "")
              }
              onChange={(event) =>
                this.handleCardDetailsInput(event, "cvcNum", "cvvTouched")
              }
              name="cvcNum"
              maxLength="4"
              id="cvcNum"
              disabled={this.state.submitting}
              onKeyUp={(event) => this.validateCVVNum(event)}
              placeholder="CVV"
            />
          </div>
        </div> */}
        <div className='col-12'>
          {this.state.submitting && <Loading />}
          <button         
            disabled={this.payButtonValidation() || this.state.submitting}
            onClick={this.onCheckOut}
          >
            Buy Now
          </button>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
