import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
//////0424
import TextField from "@material-ui/core/TextField";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import countries from "./countries";
import visaLogo1 from '../images/logo/visa.svg';
import visaLogo from '../images/logo/visa (1).svg'
import discoverLogo1 from '../images/logo/discover (1).svg'
import discoverLogo from '../images/logo/discover.svg'
import mastercardLogo1 from '../images/logo/mastercard (1).svg'
import mastercardLogo from '../images/logo/mastercard.svg'
import exprLogo1 from '../images/logo/amex.svg'
import exprLogo from '../images/logo/amex (1).svg'
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
/////////

import _ from "lodash";
import Loading from "./common/loading";
import subscribtion from "../services/subscribtion";
import auth from "../services/authServices";
import Joi from "joi-browser";
import order from "../services/order";
import StylesDefineYouBest from "./common/styleDefinesYou";
import CheckoutForm from "./CheckoutForm";
import HeaderCommon from "./common/header-login";
import Footer from "./common/footer";
import subscribe_img from "../images/subscribe-image-990x520.jpg";
import { PayPalButton } from "react-paypal-button-v2";
import "react-paypal-checkout-button/dist/index.css";
//import stripe_image from "../images/powered_by_stripe.svg";

class Subscribe extends Component {
  state = {
    cardType:'',
    labelWidth: 0,
    payment_method: "credit",
    subscribed: false,
    couponCode: "",
    stripeCustId: "",
    couponCodeApplied: false,
    validCouponDetails: {},
    finalAmount: "",
    couponMessageError: "",
    couponMessageSuccess: "",
    subscribtionPlans: [],
    selectedPlan: {},
    paypalSubscriptionID: "",
    selectedPlanIndex: 0,
    promoDiscountAmount: 0,
    finalPaybleAmount: "",
    openPromo: false,
    addressDetails: {
      shippingFirstName: "",
      shippingLastName: "",
      shippingAddress: "",
      shippingCity: "",
      //////////0424
      shippingState: "",
      ///////////////
      shippingPostcode: "",
      shippingCountry: "USA",
      billingFirstName: "",
      billingLastName: "",
      billingAddress: "",
      billingCity: "",
      ///////0424///
      billingState: "",
      //////////
      billingPostcode: "",
      billingCountry: "USA",
    },
    error: {},
    orderDetails: {},
    shippingSuccessMessage: "",
    shippingErrorMessage: "",
    checked: false,
    formValid: false,
    submitting: false,
    pageLoading: false,
    formSubmitting: false,
  };

  schema = {
    shippingFirstName: Joi.string().required().label("Shipping First Name"),
    shippingLastName: Joi.string().required().label("Shipping Last Name"),
    shippingAddress: Joi.string().required().label("Shipping Address"),
    shippingCity: Joi.string().required().label("Shipping City"),
    //////0424
    shippingState: Joi.string().required(),
    shippingPostcode: Joi.number().required().label("Shipping Postcode"),
    shippingCountry: Joi.string(),
    billingFirstName: Joi.string().required().label("Billing First Name"),
    billingLastName: Joi.string().required().label("Billing Last Name"),
    billingAddress: Joi.string().required().label("Billing Address"),
    billingCity: Joi.string().required().label("Billing City"),
    ////////0424
    billingState: Joi.string().required(),
    billingPostcode: Joi.number().required().label("Billing Postcode"),
    billingCountry: Joi.string(),
  };

  async componentDidMount() {
    this.setState({
      labelWidth: 110
    });
    this.setState({ pageLoading: true });
    try {
      const { data: subscriptions } = await auth.getCurrentUserSubscribtion();
      console.log("subscriptions", subscriptions.subscriptions);
      if (
        subscriptions.subscriptions &&
        subscriptions.subscriptions.data.length > 0
      ) {
        window.location.href = "/profile";
        //this.props.history.push("/profile");
      }
    } catch (ex) {
      console.log("error getting customer subscription", ex);
    }
    try {
      const { data: sub } = await subscribtion.getSubscribtionPlan();
      this.setState({
        subscribtionPlans: sub.plans,
        selectedPlan: sub.plans[0],
        finalPaybleAmount: sub.plans[0].price,
      });
    } catch (ex) {
      console.log("error getting subscription plan", ex);
    }
    this.setState({ pageLoading: false });
  }

  enableFormSubmitting = () => {
    this.setState({ formSubmitting: true });
  };

  disableFormSubmitting = () => {
    this.setState({ formSubmitting: false });
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
    const { addressDetails } = this.state;
    if (!this.state.checked) {
      let addressCopy = {
        shippingFirstName: addressDetails.shippingFirstName,
        shippingLastName: addressDetails.shippingLastName,
        shippingAddress: addressDetails.shippingAddress,
        shippingCity: addressDetails.shippingCity,
        shippingState: addressDetails.shippingState,
        shippingPostcode: addressDetails.shippingPostcode,
        shippingCountry: "USA",
        billingFirstName: addressDetails.shippingFirstName,
        billingLastName: addressDetails.shippingLastName,
        billingAddress: addressDetails.shippingAddress,
        billingCity: addressDetails.shippingCity,
        /////0424
        billingState: addressDetails.shippingState,
        ///////////
        billingPostcode: addressDetails.shippingPostcode,
        billingCountry: "USA",
      };

      this.setState(
        {
          addressDetails: addressCopy,
        },
        function () {
          if (this.validate() !== null) {
            return;
          } else {
            if (this.state.finalPaybleAmount != 0) {
              this.setState({ payment_method: "credit" });
            } 
          }
        }
      );
    } else {
      let addressCopy = {
        shippingFirstName: addressDetails.shippingFirstName,
        shippingLastName: addressDetails.shippingLastName,
        shippingAddress: addressDetails.shippingAddress,
        shippingCity: addressDetails.shippingCity,
        shippingState: addressDetails.shippingState,
        shippingPostcode: addressDetails.shippingPostcode,
        shippingCountry: "USA",
        billingFirstName: "",
        billingLastName: "",
        billingAddress: "",
        billingCity: "",
        ///////////0424
        billingState: "Alabama",
        ////////////
        billingPostcode: "",
        billingCountry: "USA",
      };

      this.setState(
        {
          addressDetails: addressCopy,
        },
        function () {
          if (this.validate() !== null) {
            return;
          } else {
            if (this.state.finalPaybleAmount != 0) {
              this.setState({ payment_method: "credit" });
            } 
          }
        }
      );
    }
  };

  handleCreditPay = () => {
    this.setState({ payment_method: "credit" });
  };

  handlePayPalpay = () => {
    if (this.validate() !== null) {
      alert("Please Complete Shipping & Billing Address");
      return;
    } else {
      if (this.state.finalPaybleAmount != 0) {
        this.setState({ payment_method: "paypal" });
      } else {
        this.setState({ payment_method: "paypal_coupon" });
        alert("Invalid Discount Code!");
      }
    }
  };

  //////////0424
  handleChangeRegion = (event) => {
    // setRegion(event.target.value);
    // this.setState({
    //   addressDetails.billingState
    // })
    const addressDetails = { ...this.state.addressDetails };
    addressDetails[event.target.name] = event.target.value;
    this.setState({ addressDetails });
  };
  handleChangeCardType = (event) => {
    this.setState({cardType:event})
  }
  //////////
  createOrder = () => {
    const { user } = this.props;
    let {
      selectedPlan,
      couponCode,
      promoDiscountAmount,
      finalPaybleAmount,
      couponCodeApplied,
      addressDetails,
      paypalSubscriptionID,
    } = this.state;
    console.log(
      user,
      selectedPlan,
      couponCode,
      promoDiscountAmount,
      finalPaybleAmount,
      couponCodeApplied,
      addressDetails
    );
    order.createOrderSubscriptionPaypal(
      user,
      selectedPlan,
      addressDetails,
      couponCode,
      promoDiscountAmount,
      finalPaybleAmount,
      couponCodeApplied,
      paypalSubscriptionID
    );
  };

  validate = () => {
    const { addressDetails } = this.state;
    const result = Joi.validate(addressDetails, this.schema, {
      abortEarly: false,
    });

    if (!result.error) return null;

    const error = {};
    for (let item of result.error.details) {
      error[item.path[0]] = item.message;
    }

    return error;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const error = { ...this.state.error };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const addressDetails = { ...this.state.addressDetails };
    addressDetails[input.name] = input.value;
    this.setState({ addressDetails, error });
    if (this.validate() !== null) {
      return;
    } else {
      if (this.state.finalPaybleAmount != 0) {
        this.setState({ payment_method: "paypal" });
      } else {
        this.setState({ payment_method: "paypal_coupon" });
        alert("promocode!");
      }
    }
  };

  handleSubscription = () => {
    this.setState({ subscribed: true });
  };

  validateCouponForm = () => {
    const { couponCode } = this.state;
    return !couponCode;
  };

  validateSubscribeButton = () => {
    const { selectedPlan } = this.state;
    return _.isEmpty(selectedPlan);
  };

  handleSelectPlan = (plan) => {
    const index = this.state.subscribtionPlans.indexOf(plan);

    const selectedPlan = plan;
    this.setState({ selectedPlan, selectedPlanIndex: index });
  };

  couponCode = (e) => {
    const couponCode = e.currentTarget.value;
    this.setState({ couponCode });
    this.validateCouponForm();
  };

  openPromo = () => {
    this.setState({ openPromo: true });
  };

  removePromoCode = () => {
    this.setState({ openPromo: false });
  };

  validatePromoCode = async (e) => {
    e.preventDefault();

    if (this.validateCouponForm()) {
      alert("Enter your coupon");
      return;
    }

    if (this.validate() !== null) {
      alert("Enter your Address");
      return;
    }

    const { user } = this.props;
    const { couponCode } = this.state;

    this.setState({ submitting: true });
    try {
      const { data: validatePromoCodeData } = await order.validatePromoCode(
        user,
        couponCode
      );
      console.log(
        "await order.validatePromoCode(user, couponCode);",
        order.validatePromoCode(user, couponCode)
      );
      this.setState({
        couponCodeApplied: true,
        openPromo: false,
        validCouponDetails: validatePromoCodeData,
        couponMessageError: "",
      });

      this.calculateDiscount();
    } catch (validatePromoCodeErr) {
      console.error("validate promo code error: ", validatePromoCodeErr);
      this.setState({ couponMessageError: "Invalid Promo Code!" });
    }
    if (
      this.state.payment_method == "paypal" &&
      this.state.finalPaybleAmount == 0
    ) {
      this.setState({ payment_method: "paypal_coupon" });
    }
    this.setState({ submitting: false });
  };

  handleRemovePromo = () => {
    const { selectedPlan } = this.state;
    if (this.state.payment_method == "paypal_coupon") {
      this.setState({ payment_method: "paypal" });
    }
    this.setState({
      couponCodeApplied: false,
      openPromo: false,
      validCouponDetails: {},
      couponMessageError: "",
      couponCode: "",
      finalPaybleAmount: selectedPlan.price,
    });
  };

  calculateDiscount = () => {
    const { validCouponDetails, selectedPlan } = this.state;
    if (validCouponDetails.discountType === "Percentage") {
      const discount = (selectedPlan.price * validCouponDetails.amount) / 100;

      let finalPaybleAmount = selectedPlan.price - discount;
      // alert(finalPaybleAmount)
      this.setState({ promoDiscountAmount: discount, finalPaybleAmount });
    } else {
      const flatDiscount = selectedPlan.price - validCouponDetails.amount;
      let finalPaybleAmount = selectedPlan.price - flatDiscount;
      // alert(finalPaybleAmount)
      this.setState({
        promoDiscountAmount: flatDiscount,
        finalPaybleAmount: finalPaybleAmount,
      });
    }
  };

  couponButtonValidation = () => {
    if (!this.validateCouponForm() && !this.state.submitting) return false;
    return true;
  };

  render() {
    const {
      couponMessageError,
      selectedPlan,
      subscribed,
      subscribtionPlans,
      couponCodeApplied,
      validCouponDetails,
      couponCode,
      selectedPlanIndex,
      promoDiscountAmount,
      finalPaybleAmount,
      openPromo,
      addressDetails,
      error,
      shippingSuccessMessage,
      shippingErrorMessage,
      checked,
    } = this.state;
    const { user } = this.props;
    const onSuccess = (payment) => {
      this.createOrder();
    };
    const onCancel = () => {
      console.log("cancel");
    };
    const createSubscription = async (data, actions) => {
      if (this.state.couponCodeApplied) {
        return actions.subscription.create({
          plan_id: this.state.validCouponDetails.plan_id,
        });
      } else {
        return actions.subscription.create({
          plan_id: "P-6HB54450U36741434MCIKLEA",
        });
      }
    };

    const onError = (err) => {
      console.log("Error");
    };

    const onApprove = (data, detail) => {
      this.setState(
        {
          paypalSubscriptionID: data.subscriptionID,
        },
        function () {
          this.createOrder();
        }
      );

    };
    return (
      <div>
        {this.state.pageLoading && <Loading />}
        {!this.state.pageLoading && (
          <React.Fragment>
            <HeaderCommon />
            <div className="subscribeArea">
              {!subscribed && (
                <div className="mainSubscribeArea">
                  <div className="subscribe_img">
                    <img src={subscribe_img} alt="Subscribe img" />
                  </div>

                  <div className="subscribe_right">
                  <h1>
                      Subscription Plan
                      <br />
                      <span>Benefits</span>
                    </h1>
                    <div className="commonPlanAreaMain">
                      {subscribtionPlans.map((plan, index) => (
                        <div key={plan._id} className="commonPlanArea">
                          <label
                            className={
                              selectedPlanIndex === index ? "active" : ""
                            }
                            htmlFor={plan.subscriptionId}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="subscribtionRadio"
                              id={plan.subscriptionId}
                              value={plan.price}
                              onChange={() => this.handleSelectPlan(plan)}
                            />
                            <p className="planText">
                              <span className="price">{plan.name}/${plan.price}</span>                              
                            </p>                            
                              <p className="planDetails"><span aria-label="green check mark" role="img">✅</span> Not a Jewelry Rental, Yours to Keep Forever!</p>
                              <p className="planDetails"><span aria-label="green check mark" role="img">✅</span> Free shipping. Cancel at Anytime. No Hidden fees.</p>
                              <p className="planDetails"><span aria-label="green check mark" role="img">✅</span> Hand-Picked Jewelry Pieces Based On Your Style.</p>
                              <p className="planDetails"><span aria-label="green check mark" role="img">✅</span> Retake the Fashion Style Survey at Anytime.</p>
                              <p className="planDetails"><span aria-label="green check mark" role="img">✅</span> High Quality Jewelry; 100% Satisfaction Guaranteed.</p>                        
                            
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="subscriptionForm">
                      <button
                        onClick={this.handleSubscription}
                        disabled={this.validateSubscribeButton()}
                        className="subscribe_button"
                      >
                        proceed
                      </button>
                    </div>
                    {/* <p className="termsSubs">
                      * Cancel Anytime, No Obligations & Renews Automatically!
                    </p> */}
                  </div>
                </div>
              )}

              {subscribed && (
                <div className="container">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="card shippingAddress">
                        <React.Fragment>
                          <div className="card-title">
                            Shipping & Billing Address
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                {shippingSuccessMessage && (
                                  <div
                                    className="alert alert-success"
                                    role="alert"
                                  >
                                    {shippingSuccessMessage}
                                  </div>
                                )}
                                {shippingErrorMessage && (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {shippingErrorMessage}
                                  </div>
                                )}
                              </div>
                            </div>
                            <React.Fragment>
                              <form>
                                <fieldset disabled={shippingSuccessMessage}>
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <h3>Ship To</h3>
                                      <div className="row">
                                        <div className="col mr-0 pr-1">
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            name="shippingFirstName"
                                            value={
                                              addressDetails.shippingFirstName
                                            }
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                          /> */}
                                          <TextField
                                            type="text"
                                            className="signupForm"
                                            placeholder="First Name"
                                            name="shippingFirstName"
                                            value={
                                              addressDetails.shippingFirstName
                                            }
                                            inputProps={{style: {textTransform: 'capitalize'}}}
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                            fullWidth
                                            label="First name"
                                            variant="outlined"
                                          />

                                          <div className="invalid-feedback">
                                            {error.shippingFirstName}
                                          </div>
                                        </div>
                                        <div className="col ml-0 pl-1">
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            name="shippingLastName"
                                            value={
                                              addressDetails.shippingLastName
                                            }
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                          /> */}
                                          <TextField
                                            type="text"
                                            className="signupForm"
                                            placeholder="Last Name"
                                            name="shippingLastName"
                                            value={
                                              addressDetails.shippingLastName
                                            }
                                            inputProps={{style: {textTransform: 'capitalize'}}}
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                            fullWidth
                                            label="Last name"
                                            variant="outlined"
                                          />
                                          <div className="invalid-feedback">
                                            {error.shippingLastName}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row" style={{marginTop:"15px"}}>
                                        <div className="col">
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address"
                                            name="shippingAddress"
                                            value={
                                              addressDetails.shippingAddress
                                            }
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                          /> */}
                                          <TextField
                                            type="text"
                                            className="signupForm"
                                            placeholder="Address"
                                            name="shippingAddress"
                                            inputProps={{style: {textTransform: 'capitalize'}}}
                                            value={
                                              addressDetails.shippingAddress
                                            }
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                            fullWidth
                                            variant="outlined"
                                            label="Shipping Address "
                                          />
                                          <div className="invalid-feedback">
                                            {error.shippingAddress}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row" style={{marginTop:"15px"}}>
                                        <div className="col">
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="City"
                                            name="shippingCity"
                                            value={addressDetails.shippingCity}
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                          /> */}
                                          <TextField
                                            type="text"
                                            type="text"
                                            className="signupForm"
                                            placeholder="City"
                                            name="shippingCity"
                                            variant="outlined"
                                            value={addressDetails.shippingCity}
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                            inputProps={{style: {textTransform: 'capitalize'}}}
                                            fullWidth
                                            label="Shipping City "
                                          />
                                          <div className="invalid-feedback">
                                            {error.shippingCity}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row" style={{marginTop:"15px",marginBottom:"15px"}}>
                                        <div className="col-7 pr-1">
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="State"
                                            name="shippingState"
                                            value={addressDetails.shippingState}
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                          /> */}
                                          {/* <TextField  type="text"
                                            type="text"
                                            className="signupForm"
                                            placeholder="State"
                                            name="shippingState"
                                            value={addressDetails.shippingState}
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                            fullWidth
                                            label="Shipping State " /> */}
                                          <FormControl variant="outlined">
                                            <InputLabel style={{marginBottom:'10px'}}   htmlFor="members">
                                              Shipping State
                                            </InputLabel>
                                            <Select
                                              style={{
                                                width: "100%"
                                              }}
                                              labelId="demo-simple-select-label1"
                                              id="demo-simple-select1"
                                              // placeholder="State"
                                              name="shippingState"
                                              value={
                                                addressDetails.shippingState
                                              }
                                              fullWidth
                                              onChange={this.handleChangeRegion}
                                              input={
                                                <OutlinedInput
                                                  labelWidth={this.state.labelWidth}
                                                  name="members"
                                                  id="members"
                                                />
                                              }
                                            >
                                              {countries
                                                .find(
                                                  ({ countryName }) =>
                                                    countryName ===
                                                    "United States"
                                                )
                                                .regions.map((region) => (
                                                  <MenuItem
                                                    value={region.name}
                                                    key={region.shortCode}
                                                  >
                                                    {region.name}
                                                  </MenuItem>
                                                ))}
                                            </Select>
                                          </FormControl>
                                          <div className="invalid-feedback">
                                            {error.shippingState}
                                          </div>
                                        </div>
                                        <div className="col-5 pl-1" style={{width:"100%"}}>
                                          {/* <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Zip"
                                            name="shippingPostcode"
                                            value={
                                              addressDetails.shippingPostcode
                                            }
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                          /> */}
                                          <TextField
                                            type="tel"
                                            style={{width:"100%"}}
                                            className="signupForm"
                                            placeholder="Zip"
                                            name="shippingPostcode"
                                            inputProps={{ maxLength: 5 }}
                                            value={
                                              addressDetails.shippingPostcode
                                            }
                                            onChange={this.handleChange}
                                            disabled={this.state.formSubmitting}
                                            
                                            variant="outlined"
                                            label="Zip Code "
                                          />
                                          <div className="invalid-feedback">
                                            {error.shippingPostcode}
                                          </div>
                                        </div>
                                        {/* <div className="col-sm">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Country"
                                            name="shippingCountry"
                                            value={
                                              addressDetails.shippingCountry
                                            }
                                            onChange={this.handleChange}
                                            disabled="disabled"
                                          />
                                        </div> */}
                                      </div>
                                    </div>
                                    <div className="col-sm-12">
                                      <div className="checkboxAdd2">
                                      <h4 style={{textAlign:"center"}}>
                                        <span className="checkBoxAdd">
                                          <input
                                            style={{fontSize:'25px',width: '20px', height: '20px'}}
                                            type="checkbox"
                                            onChange={this.handleCheck}
                                            defaultChecked={this.state.checked}
                                            disabled={this.state.formSubmitting}
                                          />{" "}
                                          Billing Address: Same as shipping
                                        </span>
                                      </h4>
                                      </div>
                                      <div
                                        className={
                                          "billingArea " +
                                          (checked ? "hide" : "show")
                                        }
                                      >
                                        <h3>Bill To </h3>
                                        <div className="row" style={{marginTop:"15px"}}>
                                          <div className="col mr-0 pr-1">
                                            {/* <input
                                               type="text"
                                               className="form-control"
                                               placeholder="First Name"
                                               name="billingFirstName"
                                               value={addressDetails.billingFirstName}
                                               onChange={this.handleChange}
                                               disabled={
                                                 this.state.formSubmitting ||
                                                 this.state.checked
                                              }
                                            /> */}
                                            <TextField
                                              type="text"
                                              type="text"
                                              className="signupForm"
                                              placeholder="First Name"
                                              name="billingFirstName"
                                              value={
                                                addressDetails.billingFirstName
                                              }
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                              fullWidth
                                              label="First Name "
                                              variant="outlined"
                                              inputProps={{style: {textTransform: 'capitalize'}}}
                                            />
                                            <div className="invalid-feedback">
                                              {error.billingFirstName}
                                            </div>
                                          </div>
                                          <div className="col  ml-0 pl-1">
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Last Name"
                                              name="billingLastName"
                                              value={addressDetails.billingLastName}
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                            /> */}
                                            <TextField
                                              type="text"
                                              type="text"
                                              className="signupForm"
                                              placeholder="Last Name"
                                              name="billingLastName"
                                              value={
                                                addressDetails.billingLastName
                                              }
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                              fullWidth
                                              label="Last Name "
                                              variant="outlined"
                                              inputProps={{style: {textTransform: 'capitalize'}}}
                                            />
                                            <div className="invalid-feedback">
                                              {error.billingLastName}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row" style={{marginTop:"15px"}}>
                                          <div className="col">
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Billing Address"
                                              name="billingAddress"
                                              value={
                                                addressDetails.billingAddress
                                              }
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                            /> */}
                                            <TextField
                                            style={{width:"100%"}}
                                              type="text" 
                                              className="signupForm"
                                              placeholder="Billing Address"
                                              name="billingAddress"
                                              value={
                                                addressDetails.billingAddress
                                              }
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                              inputProps={{style: {textTransform: 'capitalize'}}}
                                              variant="outlined"
                                              label="Billing Adress "
                                            />
                                            <div className="invalid-feedback">
                                              {error.billingAddress}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row" style={{marginTop:"15px"}}>
                                          <div className="col">
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Billing City"
                                              name="billingCity"
                                              value={addressDetails.billingCity}
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                            /> */}
                                            <TextField
                                              type="text"
                                              className="signupForm"
                                              placeholder="Billing City"
                                              name="billingCity"
                                              value={addressDetails.billingCity}
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                              inputProps={{style: {textTransform: 'capitalize'}}}
                                              fullWidth
                                              label="Billing City "
                                              variant="outlined"
                                            />
                                            <div className="invalid-feedback">
                                              {error.billingCity}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row" style={{marginTop:"15px"}}>
                                          <div className="col-7 pr-1">
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Billing State"
                                              name="billingState"
                                              value={
                                                addressDetails.billingState
                                              }
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                            /> */}
                                            {/* <CountryDropdown
                                              // value={country}
                                              // onChange={(val) => this.selectCountry(val)}
                                               />
                                            <RegionDropdown
                                              country="United States"
                                              name="billingState"
                                              value={
                                                addressDetails.billingState
                                              }
                                              
                                              onChange={this.handleChange} 
                                              /> */}
                                            <FormControl variant="outlined">
                                            <InputLabel style={{marginBottom:'10px'}}   htmlFor="members1">
                                              Shipping State
                                            </InputLabel>
                                              <Select
                                                type="select"
                                                style={{
                                                  width: "100%"
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"                                            
                                                name="billingState"
                                                input={
                                                  <OutlinedInput
                                                    labelWidth={this.state.labelWidth}
                                                    name="members1"
                                                    id="members1"
                                                  />
                                                }
                                                value={
                                                  addressDetails.billingState
                                                }
                                                onChange={
                                                  this.handleChangeRegion
                                                }
                                              >
                                                {countries
                                                  .find(
                                                    ({ countryName }) =>
                                                      countryName ===
                                                      "United States"
                                                  )
                                                  .regions.map((region) => (
                                                    <MenuItem
                                                      value={region.name}
                                                      key={region.shortCode}
                                                    >
                                                      {region.name}
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            </FormControl>

                                            {/* <TextField  type="text"
                                               type="text"
                                               className="signupForm"
                                               placeholder="Billing State"
                                              name="billingState"
                                              value={
                                                addressDetails.billingState
                                              }
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                              fullWidth
                                              label="Billing State " /> */}
                                            <div className="invalid-feedback">
                                              {error.billingState}
                                            </div>
                                          </div>
                                          <div className="col-5 pl-1">
                                            {/* <input
                                              type="tel"
                                              className="form-control"
                                              placeholder="Zip"
                                              name="billingPostcode"
                                              value={
                                                addressDetails.billingPostcode
                                              }
                                              onChange={this.handleChange}
                                              disabled={
                                                this.state.formSubmitting ||
                                                this.state.checked
                                              }
                                            /> */}
                                            <TextField
                                              type="tel"
                                              className="signupForm"
                                              placeholder="Zip"
                                              name="billingPostcode"
                                              value={
                                                addressDetails.billingPostcode
                                              }
                                              inputProps={{ maxLength: 5 }}
                                              onChange={this.handleChange}
                                              disabled={this.state.formSubmitting ||this.state.checked}
                                              fullWidth
                                              variant="outlined"
                                              label="Zip Code "
                                            />
                                            <div className="invalid-feedback">
                                              {error.billingPostcode}
                                            </div>
                                          </div>
                                          {/* <div className="col-sm">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Country"
                                              name="billingCountry"
                                              value={
                                                addressDetails.billingCountry
                                              }
                                              onChange={this.handleChange}
                                              disabled="disabled"
                                            />
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                            </React.Fragment>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card styleDefinesYou">
                        <StylesDefineYouBest
                          selectedPlan={selectedPlan}
                          user={user}
                          promocode={couponCode}
                          promoDiscountAmount={promoDiscountAmount}
                          finalPaybleAmount={finalPaybleAmount}
                          couponCodeApplied={couponCodeApplied}
                        />

                        
<div className="subscriptionForm">
                                    {!couponCodeApplied && (
                                      <h3
                                        onClick={this.openPromo}
                                        style={{
                                          marginTop: "-600px !important",
                                        }}
                                      >
                                        Do you have a Promo code?
                                      </h3>
                                    )}
                                    <form className="form-inline couponCodeApply">
                                      {!!couponMessageError && (
                                        <div className="invalid-feedback">
                                          <span onClick={this.removePromoCode}>
                                            <i className="fa fa-times" />
                                          </span>{" "}
                                          {couponMessageError}
                                        </div>
                                      )}
                                      {!!couponCodeApplied && (
                                        <React.Fragment>
                                          <div className="valid-feedback showMSG">
                                            <p>
                                              <i className="fa fa-check" />{" "}
                                              <span>
                                                {validCouponDetails.promocode}
                                              </span>{" "}
                                              - Applied successfully{" "}
                                              <span className="amountDiscount">
                                                - ${promoDiscountAmount}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="couponApplied">
                                            <p>
                                              {validCouponDetails.amount}
                                              {validCouponDetails.discountType ===
                                              "Percentage"
                                                ? "% off on your order"
                                                : " USD off on your order"}
                                              <span
                                                className="couponRemoved"
                                                onClick={this.handleRemovePromo}
                                              >
                                                Remove
                                              </span>
                                            </p>
                                          </div>
                                        </React.Fragment>
                                      )}
                                      {openPromo && (
                                        <React.Fragment>
                                          <div className="col-12 pr-0">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="couponCode"
                                            placeholder="Coupon Code"
                                            onChange={this.couponCode}
                                            value={couponCode}
                                          />
                                          <button
                                            type="submit"
                                            onClick={this.validatePromoCode}
                                            disabled={this.couponButtonValidation()}
                                            className="apply_copuponButton"
                                          >
                                            Apply
                                          </button>
                                          </div>
                                        </React.Fragment>
                                      )}
                                    </form>
                                  </div>



                        <div className="card">

                        
                          <div className="card-body">                            
                            <div className="stripeArea">
                              <StripeProvider apiKey="pk_live_EGB0Q8gSBjOeLeCWSV1klsmf">
                                <div className="example">
                                  
                                  <div className="row payment_method" >
                                    {/* <div className="payment_method_title">
                                      Choose Payment method
                                    </div> */}
                                    <div className='col-12' style={{ display: "flex",width:'100%' }}>
                                      <div style={{justifyContent:"center",alignItems:"center"}}> 
                                      <Radio
                                        // style={{ marginLeft: "15px",height:'35px' }}
                                         checked={this.state.payment_method === "paypal"||this.state.payment_method==='paypal_coupon'}
                                        onChange={this.handlePayPalpay}
                                        value="a"
                                        name="radio-button-demo"
                                        inputProps={{ "aria-label": "A" }}
                                      />
                                      </div>
                                      <div className="col-10 px-2">
                                      {this.state.payment_method !=
                                        "paypal" && (
                                        <button
                                          className="paypal_checkout"
                                          // style={{ marginBottom: "10px" }}
                                          onClick={this.handlePayPalpay}
                                        >
                                          <i>PayPal</i> Checkout
                                        </button>
                                      )}
                                      {this.state.payment_method ==
                                        "paypal" && (
                                        <PayPalButton
                                          style={{
                                            layout: "vertical",
                                            color: "gold",
                                            shape: "rect",
                                            label: "pay",
                                            size:"responsive"
                                          }}
                                          options={{
                                            clientId:
                                              "ATCyplbTvc3SUCvQPGyigFYJaRFLYVFNmhRtcyMLtBK1peGIyFRTyTHIGpp8a8J4Ba-xaZBNn4t868hu",
                                            disableFunding: "card,credit",
                                            vault: true,
                                          }}
                                          amount={300}
                                          currency="USD"
                                          // onSuccess={onSuccess}

                                          createSubscription={(data, details) =>
                                            createSubscription(data, details)
                                          }
                                          onApprove={(data, details) =>
                                            onApprove(data, details)
                                          }
                                          onError={(err) => onError(err)}
                                          catchError={(err) => onError(err)}
                                          onCancel={(err) => onCancel(err)}

                                          // onCancel={onCancel}
                                        />
                                      )}
                                      </div>
                                    </div>

                                    <div className='col-12' style={{ display: "flex",width:'100%' }}>
                                    <div style={{justifyContent:"center",alignItems:"center"}}> 
                                      <Radio
                                        //  style={{ marginLeft: "5px" ,height:'35px'}}
                                        checked={this.state.payment_method === "credit"}
                                        onChange={this.handleCreditPay}
                                        value="a"
                                        name="radio-button-demo"
                                        inputProps={{ "aria-label": "A" }}
                                      />
                                      </div>
                                      <div className="col-10 px-2 pt-1">
                                      <button
                                        className="credit_card"
                                        style={{ marginBottom: "10px" }}
                                        onClick={this.handleCreditPay}
                                      >
                                        <span>Pay With Credit Card</span> 
                                      </button>
                                      </div>

                                    </div>
                                  </div>
                                {this.state.payment_method==='credit'&&
                                  <div className="payment_image" style={{ display: "flex",margin:'8px 0px 13px 65px',width: '12rem', }}>
                                      <div className="col-3 px-1"> 
                                         <img width='100%' height='100%'  src={this.state.cardType=='visa'? visaLogo:visaLogo1} alt="Visa " />
                                      </div>
                                      <div className="col-3 px-1"> 
                                         <img width='100%' height='100%'  src={this.state.cardType=='discover'? discoverLogo:discoverLogo1} alt="Discover" />
                                      </div>
                                      <div className="col-3 px-1"> 
                                         <img width='100%' height='100%' src={this.state.cardType=='master'?mastercardLogo:mastercardLogo1} alt="Master" />
                                      </div>
                                      <div className="col-3 px-1"> 
                                         <img width='100%' height='100%'  src={this.state.cardType=='ame'?exprLogo:exprLogo1} alt="Ame" />
                                      </div>
                                  </div>
                                }
                                  {this.state.payment_method ==
                                    "paypal_coupon" && (
                                    <button
                                      className="paypal_checkout"
                                      style={{ marginBottom: "10px" }}
                                      onClick={this.handlePayPalpay}
                                    >
                                      Pay now
                                    </button>
                                  )}

                                  {this.state.payment_method == "credit" ? (
                                    <Elements>
                                      <CheckoutForm
                                        finalPaybleAmount={finalPaybleAmount}
                                        handleChangeCardType={this.handleChangeCardType}
                                        allData={this.state}
                                        formValid={this.validate()}
                                        user={user}
                                        enableSubmitting={
                                          this.enableFormSubmitting
                                        }
                                        disableSubmitting={
                                          this.disableFormSubmitting
                                        }
                                      />
                                    </Elements>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </StripeProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Footer />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Subscribe;
